<!-- 操作成功提示 -->
<template>
  <el-dialog
    :visible="visible"
    title="温馨提示"
    width="600px"
    :show-close="false"
  >
    <div class="el-message-box__container">
      <div class="el-message-box__status el-icon-success" />
      <div class="el-message-box__message">
        <p>
          保存成功，您还可以继续以下操作 或者 <router-link class="el-link el-link--primary is-underline" :to="{name: 'orgIndex'}">返回首页</router-link>
        </p>
      </div>
    </div>
    <el-row>
      <el-col class="step-cnt" :span="12">
        <img class="img-response" src="@/assets/images/icon-contract.png" alt="合同管理">
        <strong class="title">合同管理</strong>
        <p class="font-12 color-gray desc">维护企业的合同模板，有些场景需要合同信息，如电子签定劳动合同...</p>
        <el-button class="link-btn" type="primary" size="small">
          <router-link :to="{name: 'companyContract'}">去完善</router-link>
        </el-button>
      </el-col>
      <el-col class="step-cnt" :span="12">
        <img class="img-response" src="@/assets/images/icon-seal.png" alt="印章管理">
        <strong class="title">印章管理</strong>
        <p class="font-12 color-gray desc">维护企业的印章，有些场景需要企业印章信息，如电子签定劳动合同...</p>
        <el-button class="link-btn" type="primary" size="small">
          <router-link :to="{name: 'companySeal'}">去完善</router-link>
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  methods: {
    dialogToggle(visible) {
      this.visible = visible
    }
  }
}
</script>

<style lang="scss" scoped>
.step-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .title {
    margin: 10px 0 5px;
  }
  .desc {
    width: 70%;
  }
  .link-btn {
    margin-top: 10px;
    width: 100px;
  }
}
</style>
