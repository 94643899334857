<template>
  <div class="app-container page-main ">
    <div style="width: 100%; height: 40px">
      <span
          style="vertical-align: sub; font-size: 16px; font-weight: 600">{{ $route.query.isEdit === 'true' ? '编辑' : '创建' }}</span>
      <!--      <el-button type="primary" size="mini" style="float: right" @click="changehasSave">编辑</el-button>-->
    </div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" :show-message="showMessage">
      <!-- 企业信息 -->
      <el-card class="card-wrap">
        <div slot="header" class="card-title">
          <span class="info">公司信息</span>
        </div>
        <div>
          <el-row>
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" :xl="{span: 6}">
              <el-form-item label="公司名称:" prop="org.orgName">
                <span v-if="hasSave" style="font-size: 12px">{{ form.org.orgName }}</span>
                <el-input v-else v-model="form.org.orgName" placeholder="请填写营业执照上的公司名称" style="width: 250px"/>
              </el-form-item>
            </el-col>
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" :xl="{span: 6}">
              <el-form-item :label="'统一社会信用代码:'" class="label-break" prop="org.unifiedSocialCreditCode">
                <span v-if="hasSave"
                      style="font-size: 12px">{{ form.org.unifiedSocialCreditCode }}</span>
                <el-input v-else v-model="form.org.unifiedSocialCreditCode" placeholder="统一社会信用代码，请与营业执照一致" style="width: 250px"/>
              </el-form-item>
            </el-col>
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" :xl="{span: 6}" >
              <el-form-item label="公司性质:" prop="org.qyxz">
                <span v-if="hasSave" style="font-size: 12px">{{ form.org.qyxz === '0' ? '一般纳税人' : '小规模纳税人' }}</span>
                <el-select v-else style="width: 250px;" v-model="form.org.qyxz" placeholder="请选择" >
                  <el-option value="0" label="一般纳税人"></el-option>
                  <el-option value="1" label="小规模纳税人"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" :xl="{span: 6}">
              <el-form-item :label="'公司编码:'" class="label-break" prop="org.orgCode">
                <span v-if="hasSave"
                      style="font-size: 12px">{{ form.org.orgCode}}</span>
                <el-input v-else v-model="form.org.orgCode" placeholder="请输入公司编码" style="width: 250px;"/>
              </el-form-item>
            </el-col>
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" :xl="{span: 6}">
              <el-form-item :label="'企业号:'" class="label-break" prop="org.orgNum">
                <el-input v-model="form.org.orgNum" placeholder="请输入企业号" style="width: 250px;"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- 账号信息 -->
      <el-card class="card-wrap">
        <div slot="header" class="card-title">
          <span class="info">对公银行账号信息</span>
          <el-button type="primary" v-if="!hasSave" size="mini" style="float: right" :disabled="hasSave"
                     icon="el-icon-plus"
                     @click="addBank">添加
          </el-button>
        </div>
        <div>
          <el-table
              border
              size="mini"
              :data="form.bankList"
              style="width: 100%; margin-bottom: 30px;min-height: 50px"
              :header-cell-style="{background: '#f6f6f6'}">
            <el-table-column
                prop="bankName"
                label="开户行名称"
                align="center">
              <template #default="scope">
                <span v-if="hasSave">{{ scope.row.bankName }}</span>
                <el-form-item
                    v-else
                    label-width="0"
                    :prop="`bankList.${scope.$index}.bankName`"
                    :rules="[{required:true,message:'请输入支行名称，如海尔路支行'}]">
                  <el-select
                      size="mini"
                      v-model="scope.row.bankName"
                      style="width:100%"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入支行名称，如海尔路支行"
                      :remote-method="findEnterpriseList"
                      @change="handleSelect(scope.row,scope.$index,2)">
                    <el-option
                        v-for="item in selectEnterpriseList"
                        :key="item.bankName"
                        :label="item.bankName"
                        :value="item.bankName">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                prop="bankCode"
                label="开户行代码"
                align="center">
              <template #default="scope">
                <span v-if="hasSave">{{ scope.row.bankCode }}</span>
                <el-form-item
                    v-else
                    label-width="0">
                  <el-select
                      size="mini"
                      v-model="scope.row.bankCode"
                      style="width:100%"
                      filterable
                      remote
                      reserve-keyword
                      placeholder="请输入开户行代码"
                      :remote-method="findEnterpriseCodeList"
                      @change="handleCodeSelect(scope.row,scope.$index,2)">
                    <el-option
                        v-for="item in selectEnterpriseCodeList"
                        :key="item.bankBramchCode"
                        :label="item.bankBramchCode"
                        :value="item.bankBramchCode">
                      <span style="float: left">{{ item.bankBramchCode }}</span>
                      <span style="float: right; color: #8492a6; font-size: 12px">{{ item.bankName }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
<!--                <el-input readonly size="mini" v-model="scope.row.bankCode"-->
<!--                          placeholder="请正确填写开户行名称会自动带出开户行代码"></el-input>-->

              </template>
            </el-table-column>
            <el-table-column
                prop="bankCardNumber"
                label="银行卡号"
                align="center">
              <template #default="scope">
                <span v-if="hasSave">{{ scope.row.bankCardNumber }}</span>
                <el-form-item
                    v-else
                    label-width="0"
                    :prop="`bankList.${scope.$index}.bankCardNumber`"
                    :rules="[{required:true,validator: validatorNumber}]">
                  <el-input size="mini" v-model="scope.row.bankCardNumber" placeholder="请输入银行卡号"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                prop="bank"
                label="总行"
                align="center">
              <template #default="scope">
                <el-form-item
                    label-width="0"
                    :prop="`bankList.${scope.$index}.bankCategory`"
                    :rules="[{required:true,message:'请选择'}]">
                  <el-select v-model="scope.row.bankCategory" size="mini" filterable clearable
                             @change="bankChange($event,scope.$index)">
                    <el-option v-for="item in bankTolList" :key="item.banktyp"  :label="item.banknam"
                               :value="item.banktyp"></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="是否默认" align="center"  prop="isDefault">
              <template #default="scope">
                <el-form-item label-width="0">
<!--                  <el-checkbox v-model="scope.row.isDefault" size="mini"></el-checkbox>-->
<!--                  <el-radio v-model="radio" ></el-radio>-->
                  <input type="radio" name="def" :checked="scope.row.isDefault"
                         @change="defaultChange(scope.$index, scope.row)">
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" v-if="!hasSave">
              <template #default="scope">
                <el-button type="text" size="mini" @click="delBankItem(scope.row,scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-col v-if="!hasSave" class="bottom-button">
        <el-form-item label-width="0" class="flex-center">
          <el-button
              :disabled="hasSave"
              @click="onCancel"
          >取消
          </el-button>
          <el-button
              :disabled="hasSave"
              type="primary"
              :loading="loading"
              @click="saveOrg"
          >{{ loading ? '正在保存' : '确定' }}
          </el-button>

        </el-form-item>
      </el-col>
    </el-form>

    <!-- 操作成功提示 -->
    <success-dialog ref="successDialog"/>
  </div>
</template>

<script>
import BlockTitle from '@/components/BlockTitle' // 标题栏
import SuccessDialog from './success-dialog'
import CitySelect from '@/components/Select/CitySelect'
import CommonSelect from '@/components/Select/CommonSelect'
import forms from '@/mixins/forms'
//
import service from '@/api/company' // api 接口

let loadingInstance = null
export default {
  components: {
    // Template,
    // BlockTitle,
    // BlockPassword,
    CitySelect,
    SuccessDialog,
    CommonSelect,
  },
  mixins: [forms],
  data() {
    return {
      radio: '',
      selectEnterpriseCodeList: [],
      selectEnterpriseList: [],
      showMessage: true,
      hasSave: false,
      companyInfo: {
        Name: {label: '企业名称', value: '', mapKey: 'orgName'},
        CreditCode: {label: '统一社会\n信用代码', value: '', mapKey: 'unifiedSocialCreditCode'},
      },
      form: {
        org: {
          orgCode: '',
          id: '',
          orgName: '',
          orgNum: '',
          unifiedSocialCreditCode: '',
          qyxz: '',
        },
        bankList: []
      },
      loading: false, // 提交 Loading
      value: false,
      bankTolList: [],
    }
  },
  computed: {
    rules() {
      let rules = {};
      return rules
    },
  },
  mounted() {
    if (this.$route.query.isEdit === 'true') {
      this.getDetail();
    }

    this.getBankTo();
    // service.getBank({bankCode: 313468900452}).then(res => {
    //   console.log('res', res)
    // })
  },
  methods: {
    validatorNumber(rule,value,callback){
      let reg = /^[0-9]*$/;
      if(value === ''){
        callback(new Error('请输入银行卡号'));
      }else if(reg.test(value) && value.indexOf(' ') === -1){
        callback();
      }else {
        callback(new Error('无效的格式'))
      }
    },
    defaultChange(index, row) {
      for(let i in this.form.bankList) {
        this.form.bankList[i].isDefault = false;
      }
      this.form.bankList[index].isDefault = true;
      // row.status = 0
      // this.formInline.companyAccountInfo = row
      // this.formData.tableData.forEach((item, i) => {
      //   if (i !== index) item.status = 1
      // })
    },
    getBankTo() {
      service.getBankTo({}).then(resp => {
        this.bankTolList = resp.data;
      })
    },
    async findEnterpriseList(queryString, cb) {
      service.getBank({bankName: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseList = res.data.list
        } else {
          this.selectEnterpriseList = []
        }
      })
    },
    async findEnterpriseCodeList(queryString, cb){
      service.getBank({bankBramchCode: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseCodeList = res.data.list
        } else {
          this.selectEnterpriseCodeList = []
        }
      })
    },
    bankChange(val, index) {
      let obj = this.bankTolList.find(f => f.banktyp  === val);
      if (obj) {
        this.form.bankList[index].bank= obj.banknam
      }
    },
    handleSelect(item, index, met) {
      let selectEnterpriseList = this.selectEnterpriseList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankName === item.bankName
      });
      if (thisBank) {
        this.form.bankList[index].bank = thisBank[0].bank;
        this.form.bankList[index].bankName = thisBank[0].bankName;
        this.form.bankList[index].bankCode = thisBank[0].bankBramchCode;
        // this.form.bankList[index].bankCategory = thisBank[0].bankCategory;
      }
    },
    handleCodeSelect(item, index, met){
      let selectEnterpriseList = this.selectEnterpriseCodeList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankBramchCode === item.bankCode
      });
      if (thisBank) {
        this.form.bankList[index].bank = thisBank[0].bank;
        this.form.bankList[index].bankName = thisBank[0].bankName;
        this.form.bankList[index].bankCode = thisBank[0].bankBramchCode;
        // this.form.bankList[index].bankCategory  = thisBank[0].bankCategory ;
      }
    },
    // 详情
    getDetail() {
      // 企业信息
      service.getCompanyDetail({id: this.$route.query.id}).then(data => {
        let resp = data.data;
        this.loadDetailKey('org', resp);
        // 银行展示
        if (resp.orgBankVos && resp.orgBankVos.length > 0) {
          this.form.bankList = resp.orgBankVos
        }
      })
    },
    loadDetailKey(key, data) {
      if (!data) {
        return
      }
      for (let k in this.form[key]) {
        this.form[key][k] = data[k] || ''
      }
    },
    //取消
    onCancel() {
      this.$refs['form'].resetFields();
      this.hasSave = true;
      this.showMessage = false;
      this.getDetail();
    },
    //保存
    saveOrg() {
      this.loading = true;
      let data = {
        ...this.form.org,
        orgBankVos: this.form.bankList,
      }

      service.saveOrg(data).then(resp => {
        if (resp && resp.message === '成功') {
          this.$message({
            type: 'success',
            message: '企业信息保存成功！',
            showClose: true,
          });
          this.$router.push({name: 'enterprise-info'})
        }else {
          this.$message.error(resp.message)
        }

      }).finally(() => {
        this.loading = false
      })
    },
    //增加银行账号
    addBank() {
      this.form.bankList.push({
        bankSource: '',
        bankName: '',
        bankCode: '',
        cardNum: '',
        bankCategory : '',
        isDefault: this.form.bankList.length === 0,
      })
    },
    //删除银行账号
    delBankItem(item, index) {
      let list = [...this.form.bankList];
      list.splice(index, 1);
      this.form.bankList = list;
    },
  }
}
</script>

<style lang="scss" scoped>
.applyBtn {
  position: fixed;
  top: 64px;
  right: 20px;
  z-index: 2;
  padding-right: 40px;
  //background: url("../../assets/images/icon-V.png") no-repeat right center;
}

// 选择人员css
.el-input__inner {
  padding: 0 5px;

  > .el-tag {
    margin-left: 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.input-placeholder {
  padding-left: 10px;
  padding-right: 10px;
  color: #C0C4CC;
}

.lhxq-dialogtp {
  width: 100%;
  height: 60px;
  padding: 0 24px;
  position: relative;
  border-bottom: 1px solid #E3E7ED;
}

.lhxq-dialogtptext {
  font-size: 16px;
  color: #333;
}

.lhxq-dialogtpimg {
  width: 29px;
  height: 29px;
  position: absolute;
  top: 15px;
  right: 24px;
  cursor: pointer;
}

.lhxq-dialogtp.lhxq-popsavebs {
  padding: 20px;
  border-bottom: none;
}

.lhxq-popsavetext {
  padding: 0 20px;
}

.lhxq-popsavebtnbox {
  padding: 20px;
  text-align: right;
}

.lhxq-primarybtns.lhxq-primarybtndis {
  background-color: #fff;
  color: #333333;
  border-color: #E3E7ED;
}

.lhxq-primarybtns.lhxq-primarybtndis:hover {
  background-color: #fff;
  color: #666;
  border-color: #E3E7ED;
}

.lhxq-primarybtns {
  /* width: 56px; */
  height: 32px;
  line-height: 32px;
  margin-left: 16px;
}

.lhxq-primarybtns:first-child {
  margin-left: 0;
}

.lhxq-savebox {
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
  line-height: 32px;
}

.lhxq-saveinput {
  display: inline-block;
}
</style>
<style>
.lhxq-saveinput .el-input .el-input__inner {
  height: 32px;
  line-height: 32px;
}

.lhxq-dialog .el-dialog__header {
  padding: 0 !important;
}

.lhxq-dialog .el-dialog__body {
  padding: 0 !important;
}

.rembox .removeBtn {
  position: absolute;
  left: -5px;
  top: 10px;
  color: red;
  cursor: pointer
}
</style>
<style>
.page-main {
  height: 100%;
  padding: 10px;
  background: #f6f6f6;
}

.lj-dialog .el-dialog__header {
  display: none;
}

.qrBack {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5)
}


</style>
