/**
 * 全局公用数据：性别、国家、状态、民族、政治面貌等字典表
 */
import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"


/**
 * 通用字典表：
 *
 * SEX          性别
 * NATION       民族
 * MARRIAGE     婚姻状况
 * DEGREE       文化程度
 * QDAN         青岛行政区划代码
 * PG           缴费档次
 * YN           是否
 * LCT          劳动合同类型
 * EPC          就业人员类别
 * ICR          参保变更原因
 * DR           解聘原因
 * OPT          服务类型
 *
 * PROCODE      户籍所在地的省份代码
 *              户籍所在地的市级代码
 *              户籍所在地的区级代码
 *
 * JOBCODE      工种代码
 * ES           员工状态
 * EPS          员工待办状态
 * FAMILY       家庭成员类型
 * RELIGION     宗教
 * LANGUAGE     语种
 * HOUSEHOLD    户别
 * PUC          企业类型
 *
 * MANAGERTYPE  企业负责人类型
 * CHILDREN_NUMBER    子女数目
 * DEGREE_LEVEL       学位
 * SDJZ               山东建筑-负责任电话
 * JZSTANDARD         山东建筑-规格
 * JZBRANCH           山东建筑-建筑工种
 * SALARY             工资结算方式
 *
 * @param {String} parentKey key，必填，可能值见注释
 * @param {String} key key，可选
 * @param {Number} currentPage 页码，可选，默认加载全部
 * @param {Number} pageSize 条数，可选，默认加载全部
 * @returns {Promise} api Promise
 */
export function getDirectData(parentKey = null, key = null, rootkey = null, currentPage = 1, pageSize = 0) {
  if (parentKey === 'SEX') {
    return new Promise(resolve => {
      let list = [
        { value: '男', key: '1' },
        { value: '女', key: '2' }
      ]

      resolve({
        list
      })
    })
  }

  if (parentKey === 'YN') {
    return new Promise(resolve => {
      let list = [
        { value: '是', key: '1' },
        { value: '否', key: '0' }
      ]

      resolve({
        list
      })
    })
  }

  if (parentKey === 'MANAGERTYPE') {
    return new Promise(resolve => {
      let list = [
        { value: '法人', key: '1' },
        { value: '代理人', key: '2' }
      ]

      resolve({
        list
      })
    })
  }

  if (parentKey === 'CHILDREN_NUMBER') {
    return new Promise((resolve) => {
      let list = []
      for (let index = 0; index < 11; index++) {
        list.push({ value: `${index} 个`, key: index + '' })
      }

      resolve({
        list
      })
    })
  }

  if (parentKey === 'DEGREE_LEVEL') {
    return new Promise((resolve) => {
      let list = [
        { value: '学士学位', key: '学士学位' },
        { value: '硕士学位', key: '硕士学位' },
        { value: '博士学位', key: '博士学位' }
      ]

      resolve({
        list
      })
    })
  }

  let data = { parentKey, currentPage, pageSize }
  key && (data.key = key)
  rootkey && (data.rootkey = rootkey)
  // return request({
  //   url: '/dic/list',
  //   method: 'post',
  //   data
  // })
}

/**
 * 社保、医保字典表：
 *
 * IDT     证件类型
 * COUNTRY 国家
 * NVQL    国家职业资格等级
 * PTP     专业技术职务等级
 * AC      户口性质
 * PC      政治面貌
 * QDSTREETCODE 青岛市户籍所属街道
 * LABOR        劳动形式
 * WORKHOURTYPE  工作制
 *
 * @param {String} parentKey 字典表Key
 * @param {String} key key，可选
 * @param {Number} currentPage 页码，可选，默认加载全部
 * @param {Number} pageSize 条数，可选，默认加载全部
 * @returns {Promise} api Promise
 */
// export function getSBSelect(parentKey = null, key = null, currentPage = 1, pageSize = 0) {
//   let data = { parentKey, currentPage, pageSize }
//   key && (data.key = key)
//   return request({
//     url: '/dic/iList',
//     method: 'post',
//     data
//   })
// }

/**
 * CUBA字典表：
 *
 * COMPAYTYPE   企业类型
 * INDUSTRY     行业
 *
 * @param {String} rootKey 字典表Key
 * @param {String} key key，可选
 * @param {Number} currentPage 页码，可选，默认加载全部
 * @param {Number} pageSize 条数，可选，默认加载全部
 * @returns {Promise} api Promise
 */
// export function getCubaSelect(rootKey = null, key = null, currentPage = 1, pageSize = 0) {
//   let data = { rootKey, currentPage, pageSize }
//   key && (data.key = key)
//   return request({
//     url: '/dic/listCUBA',
//     method: 'post',
//     data
//   })
// }

/**
 * 获取性别的 Value 值
 *
 * @param {String} sex 性别
 * @returns {Number} value
 */
export function getSexValue(sex) {
  if (sex.includes('男')) {
    return '1'
  } else if (sex.includes('女')) {
    return '2'
  } else {
    return null
  }
}

/**
 * 根据key，获取字典表value
 * @param {String} parentKey 字典表TYPE
 * @param {String, Number} key key值
 * @returns {Object} 对象
 */
export function getValue(parentKey, key) {
  return new Promise((resolve, reject) => {
    if (!parentKey || !key) {
      reject(new Error('参数错误'))
      return
    }

    // getDirectData(parentKey, key).then(data => {
    //   let values = data.list && data.list.length ? data.list[0] : { key, value: '' }
    //   resolve(values)
    // }).catch(e => {
    //   reject(e)
    // })
  })
}

/**
 * 根据key，获取社保字典表value
 * @param {String} parentKey 字典表TYPE
 * @param {String, Number} key key值
 * @returns {Object} 对象
 */
// export function getSbValue(parentKey, key) {
//   return new Promise((resolve, reject) => {
//     if (!parentKey || !key) {
//       reject(new Error('参数错误'))
//       return
//     }
//
//     getSBSelect(parentKey, key).then(data => {
//       let values = data.list && data.list.length ? data.list[0] : { key, value: '' }
//       resolve(values)
//     }).catch(e => {
//       reject(e)
//     })
//   })
// }
