<template>
  <!--
    省市区
    联级选择器
  -->
  <el-cascader
    ref="cityCascader"
    :value="value"
    class="w-100"
    :separator="separator"
    :props="cityProps"
    :placeholder="placeholder"

    @change="change"
  />
</template>

<script>
import { getDirectData, getValue } from '@/api/common'

export default {
  name: 'CitySelect',
  props: {
    // value值，数组格式
    value: {
      type: Array,
      default: () => ([])
    },
    separator: {
      type: String,
      default: ' '
    },
    // Change 事件，是否返回选中节点的详细信息
    isDetail: {
      type: Boolean,
      default: false
    },
    // 联动级别，默认3级
    level: {
      type: Number,
      default: 3
    },
    placeholder: {
      type: String,
      default: '请选择省市区'
    }
  },
  data() {
    return {
      initValues: []
    }
  },
  computed: {
    cityProps() {
      return {
        lazy: true,
        lazyLoad: (node, resolve) => {
          let { level } = node
          let key = level === 0 ? 'PROCODE' : node.value

          // getDirectData(key).then(data => {
          //   let list = data.list.map(item => {
          //     let leaf = level >= 2
          //
          //     // 1级联动 或 港澳台：无下级
          //     if (['710000', '810000', '820000'].includes(item.key) || this.level === 1) {
          //       leaf = true
          //     }
          //     // 2级联动：判断有无下级
          //     if (this.level === 2) {
          //       if (level === 0 && ['110000', '120000', '310000', '500000'].includes(item.key)) {
          //         leaf = true
          //       } else if (level === 1) {
          //         leaf = true
          //       }
          //     }
          //
          //     return {
          //       label: item.value,
          //       value: item.key,
          //       level,
          //       leaf
          //     }
          //   })
          //   resolve(list)
          // }).catch(e => {
          //   resolve([])
          // })
        }
      }
    }
  },
  mounted(){
    this.setInitValues(this.value);
  },
  methods: {
    /**
     * 回显数据
     * 示例：this.$refs.citySelect.setInitValues([省id, 市id, 区id])
     */
    setInitValues(initValues) {
      if (initValues.length === 0) {
        return
      }
      // 省存在
      if (initValues[0]) {
        getValue('PROCODE', initValues[0]).then(data => {
          this.setCurList(0, data)
        })

        if (initValues.length >= 2 && initValues[1]) {
          // 市
          getValue(initValues[0], initValues[1]).then(data => {
            this.setCurList(1, data)
          })
          // 区
          if (initValues.length >= 3 && initValues[2]) {
            getValue(initValues[1], initValues[2]).then(data => {
              this.setCurList(2, data)
            })
          }
        }
      }
    },
    setCurList(index, data) {
      if (data) {
        this.$set(this.initValues, index, data)
        this.initValuesText()
      }
    },
    initValuesText() {
      let texts = this.initValues.map(item => item.value)
      texts = texts.join(this.separator)
      this.$refs.cityCascader.inputValue = texts
    },

    // change
    change(value) {
      if (this.isDetail) {
        value = this.$refs.cityCascader.getCheckedNodes()
      }
      this.$emit('change', value)
    }
  }
}
</script>
