<template>
  <!--
    通用 Select 下拉框
    监听 change 事件，获取选中的id

    示例：
    <common-select
      type="sex"
      :value="form.sex"
      placeholder="请选择性别"
      @change="value => { form.sex = value }"
    />
  -->
  <el-select
    :value="value"
    class="w-100"
    filterable
    clearable
    :disabled="disabled"
    :placeholder="placeholder"

    @change="change"
  >
    <el-option v-for="item in list" :key="item.id" :label="item.value" :value="item.key" />
  </el-select>
</template>

<script>
import { getDirectData, } from '@/api/common'

export default {
  name: 'CommonSelect',
  props: {
    // 字段类型，不区分大小写，必填
    // 详情见 /src/api/common.js 注释
    type: {
      type: String,
      required: true,
      default: ''
    },
    // value值，必填
    value: {
      type: String,
      default: ''
    },
    // 禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // Change 事件，是否返回选中节点的详细信息
    isDetail: {
      type: Boolean,
      default: false
    },

    // placeholder，选填
    placeholder: {
      type: String,
      default: '请选择内容'
    },

    // 是否为街道：街道是社保字典
    isStreet: { type: Boolean, default: false }
  },
  data() {
    return {
      list: []
    }
  },
  watch: {
    type(newVal, oldVal) {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    change(value) {
      if (this.isDetail) {
        value = this.list.find(item => item.key === value)
      }
      this.$emit('change', value)
    },

    // api
    getData() {
      let type = this.type.toUpperCase()
      let sbApiKeys = ['IDT', 'COUNTRY', 'NVQL', 'PTP', 'AC', 'PC', 'QDSTREETCODE', 'LABOR', 'WORKHOURTYPE']
      let cubaApiKeys = ['COMPAYTYPE', 'INDUSTRY']

      if (sbApiKeys.includes(type) || this.isStreet) {
        // 社保 字典表
        // getSBSelect(type).then(data => {
        //   this.list = data.list
        // }).catch(e => {
        //   this.list = []
        // })
      } else if (cubaApiKeys.includes(type)) {
        // getCubaSelect(type).then(data => {
        //   this.list = data.list
        // }).catch(e => {
        //   this.list = []
        // })
      } else {
        // 通用 字典表
        getDirectData(type).then(data => {
          this.list = data.list
        }).catch(e => {
          this.list = []
        })
      }
    }
  }
}
</script>
